import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';

let form: HTMLFormElement | null | undefined;
const searchBtn = document.querySelector<HTMLElement>('[data-lit-popup-open="search-popup"]');
let searchInput: HTMLInputElement | null | undefined;
const header = document.querySelector('.js-header');
let btnTimeout: NodeJS.Timeout;

const onOpen = () => {
    header?.classList.add('header--search-opened');

    if (searchBtn) {
        btnTimeout = setTimeout(() => {
            searchBtn.removeAttribute('data-lit-popup-open');
            searchBtn.setAttribute('data-lit-popup-close', 'search-popup');
        }, 1);
    }

    setTimeout(() => {
        searchInput?.focus();
    }, 500);
};

const onCloseComplete = () => {
    clearTimeout(btnTimeout);
    header?.classList.remove('header--search-opened');
    if (searchInput) searchInput.value = '';

    if (searchBtn) {
        searchBtn.removeAttribute('data-lit-popup-close');
        searchBtn.setAttribute('data-lit-popup-open', 'search-popup');
    }
};

const onSearch = (event: Event) => {
    event.preventDefault();

    if (!searchInput || !form) return;

    if (searchInput.value.length > 1) {
        window.location.href = form.action + '?q=' + searchInput.value;
    }
};

const clearInput = () => {
    if (searchInput) {
        searchInput.value = '';
        searchInput.focus();
    }
};

function init() {
    const searchPopup = document.querySelector<LitPopupElement>('.js-search-popup');
    form = searchPopup?.querySelector('.js-search-form');
    searchInput = searchPopup?.querySelector('.js-search-input');
    const clearInputTrigger = searchPopup?.querySelector('.js-search-input-clear');

    if (searchPopup) {
        searchPopup.addEventListener('open', onOpen);
        searchPopup.addEventListener('close-complete', onCloseComplete);
    }

    form?.addEventListener('submit', onSearch);
    clearInputTrigger?.addEventListener('click', clearInput);
}

const _module = { init };

export default _module;
