const NO_SCROLL_CLASS = 'no-scroll';
let wasBodyLocked = false;

export function lockBodyScroll() {
    wasBodyLocked = document.documentElement.classList.contains(NO_SCROLL_CLASS);
    if (!wasBodyLocked) {
        document.documentElement.classList.add(NO_SCROLL_CLASS);
    }
}

export function unlockBodyScroll() {
    if (wasBodyLocked) {
        document.documentElement.classList.remove(NO_SCROLL_CLASS);
    }
}

const observer = new MutationObserver(function (mutationsList, observer) {
    for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
            const wrapper = document.getElementById('js-iframe-wrapper');
            wrapper ? lockBodyScroll() : unlockBodyScroll();
        }
    }
});

function init() {
    const btn = document.querySelector<HTMLButtonElement>('.pos-banner-btn_2');
    btn ? observer.observe(document.body, { childList: true, subtree: true }) : observer.disconnect();
}

const _module = { init };

export default _module;
