import 'bvi/dist/js/bvi.min.js';

function init() {
    if (isvek) {
        new isvek.Bvi({
            target: '.js-impaired-visually-trigger',
            fontSize: 22,
        });
    }
}

const _module = { init };

export default _module;
