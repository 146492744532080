import gsap from 'gsap';

function createPreloader() {
    const preloader = document.querySelector<HTMLElement>('.js-preloader');
    let loaded = false;
    document.documentElement.classList.add('no-scroll');

    function leave(): Promise<void> {
        return new Promise((resolve) => {
            document.dispatchEvent(new Event('preloader-leave'));

            if (!loaded) {
                loaded = true;
            }

            const tl = gsap.timeline();

            tl.to(
                preloader,
                {
                    duration: 0.5,
                    yPercent: -100,
                    onComplete: () => {
                        preloader?.classList.add('is-hidden');
                        document.documentElement.classList.remove('no-scroll');
                    },
                },
                0.5,
            ).add(resolve, 0.65);
        });
    }

    function loadAsset(asset: HTMLImageElement | HTMLVideoElement): Promise<void> {
        return new Promise((resolve) => {
            if (asset instanceof HTMLImageElement) {
                if (asset.complete) {
                    resolve();
                } else {
                    asset.onload = () => resolve();
                    asset.onerror = () => resolve();
                }
            }

            if (asset instanceof HTMLVideoElement) {
                if (asset.readyState === 4) {
                    resolve();
                } else {
                    asset.addEventListener('canplay', () => resolve(), { once: true });
                    asset.addEventListener('error', () => resolve(), { once: true });
                }
            }
        });
    }

    async function loadAssetsFromElement(element: Element | Document = document) {
        const images = Array.from(element.querySelectorAll<HTMLImageElement>('img:not(.lazy):not([loading="lazy"])'));
        const videos = Array.from(element.querySelectorAll<HTMLImageElement>('video:not(.lazy):not([loading="lazy"])'));
        const assets: Array<HTMLImageElement | HTMLVideoElement> = [...images, ...videos];

        if (images.length > 0) {
            await Promise.all<any>(assets.map((asset) => loadAsset(asset)));
        }
    }

    async function loadAssets() {
        await loadAssetsFromElement(document.body);
    }

    return { leave, loadAssets } as const;
}

export const preloader = createPreloader();
