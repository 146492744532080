const header = document.querySelector<HTMLElement>('.js-header');
const leftSide = document.querySelector<HTMLElement>('.detail-page__left');
const commonRootMargin = '-7% 0% -92.9% 0%';
const threshold = [0, 1];
const themes = ['light', 'dark', 'detail', 'right-dark', 'light-transparent'];

const callback: IntersectionObserverCallback = (entries) => {
    entries.forEach((entry) => {
        const target = entry.target as HTMLElement;

        if (header && entry.isIntersecting) {
            let { theme } = target.dataset;

            if (window.matchMedia('(min-width: 1200px)').matches && target.closest('[data-theme="detail"]')) {
                if (leftSide) {
                    theme = 'detail';
                }
            }

            if (window.matchMedia('(min-width: 1200px)').matches && target.closest('[data-theme="right-dark"]')) {
                theme = 'right-dark';
            }

            if (!theme) {
                themes.forEach((item) => {
                    header.classList.remove(`header-theme--${item}`);
                });
                return;
            }

            themes
                .filter((item) => item !== theme)
                .forEach((item) => {
                    header.classList.remove(`header-theme--${item}`);
                });
            header.classList.add(`header-theme--${theme}`);
        }
    });
};

const map = new Map<HTMLElement, IntersectionObserver>();
const observersMap = new Map<string, IntersectionObserver>();

function createThemeObserver(rootMargin: string) {
    return new IntersectionObserver(callback, { rootMargin, threshold });
}

function init(container: Element | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-header-theme-trigger')).forEach((el) => {
        const rootMargin = el.dataset.themeObserverMargin || commonRootMargin;
        const usedObserver = observersMap.get(rootMargin);
        const observer = usedObserver || createThemeObserver(rootMargin);
        observer.observe(el);

        if (!usedObserver) {
            map.set(el, observer);
            observersMap.set(rootMargin, observer);
        }
    });
}

const _module = { init };

export default _module;
