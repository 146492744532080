function playVideo(event: Event) {
    const target = event.target as HTMLElement;
    const container = target.closest('.js-video-container');
    const video = container?.querySelector<HTMLVideoElement>('.js-video');

    if (video) {
        target.classList.add('is-hide');
        video.removeAttribute('hidden');
        video.play();
    }
}

function init() {
    const videoTriggers = document.querySelectorAll('.js-video-trigger');

    videoTriggers.forEach((trigger) => trigger.addEventListener('click', playVideo, { once: true }));
}

const _module = { init };

export default _module;
