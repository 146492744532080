import FormSender from '@chipsadesign/form-sender';
import createValidator from '../../modules/validator';

const SELECTOR = '.js-form';

let isSubmitting = false;

const clearAntispamInput = (form: HTMLFormElement) => {
    const checkInput = form.querySelector<HTMLInputElement>('input[name="check_val"]');
    if (checkInput) {
        checkInput.value = '';
    }
};

const showMessage = (
    form: HTMLFormElement,
    success = true,
    message = success ? 'Ваша заявка успешно отправлена' : 'Что-то пошло не так. Попробуйте ещё раз.',
) => {
    const messageContainer = form.querySelector('.js-form-message');

    if (messageContainer) {
        messageContainer.classList.remove('form-message--hidden');
        messageContainer.textContent = message;

        if (!success) {
            messageContainer.classList.add('is-error');
        }
    }
};

const hideFormMessages = (form: HTMLFormElement) => {
    const messageContainer = form.querySelector('.js-form-message');
    messageContainer?.classList.add('form-message--hidden');
};

function init() {
    const formList = document.querySelectorAll<HTMLFormElement>(SELECTOR);

    formList.forEach((form) => {
        const validator = createValidator(form, {
            scrollToInvalidInputOptions: {
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            },
        });

        const newForm = new FormSender(form, {
            shouldClearInputs: true,
            onBeforeSend: () => {
                clearAntispamInput(form);
            },
            onSuccess: ({ success, message }) => {
                showMessage(form, success, message);
            },
            onError: () => {
                showMessage(form, false, 'Пожалуйста, попробуйте еще раз');
            },
            onComplete: () => {
                setTimeout(() => hideFormMessages(form), 5000);
            },
        });

        function submitFn(e: Event) {
            if (isSubmitting) return;
            e.preventDefault();
            const isFormValid = validator.validate();

            if (isFormValid) {
                form.classList.add('is-load');

                isSubmitting = true;

                newForm.send().finally(() => {
                    isSubmitting = false;

                    form.classList.remove('is-load');
                });
            }
        }

        form.addEventListener('submit', submitFn);
    });
}

const _module = { init };

export default _module;
