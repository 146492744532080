const SELECTOR = '.js-block-custom-scroll';
let blockWithCustomCursorArr: BlockElement[];
let cursors: NodeListOf<HTMLElement>;

let cursor: HTMLElement | null;
let mouseX = 0,
    mouseY = 0;

interface BlockElement {
    removeEventListener(
        type: 'mousemove' | 'pointermove' | 'mouseenter' | 'mouseleave',
        listener: (event: MouseEvent) => any,
        options?: boolean | EventListenerOptions,
    ): void;
    addEventListener(
        type: 'mousemove' | 'pointermove' | 'mouseenter' | 'mouseleave',
        listener: (event: MouseEvent) => any,
        options?: boolean | EventListenerOptions,
    ): void;
}

function setPositions(this: Element, e: MouseEvent) {
    mouseX = e.clientX - 40;
    mouseY = e.clientY - 40;

    if (cursor) {
        cursor.style.transform = `translate3d(${mouseX}px, ${mouseY}px, 0)`;
    }
}

function addActiveClass(this: HTMLElement) {
    const indexBlock = blockWithCustomCursorArr.indexOf(this);
    if (indexBlock !== undefined) {
        cursor = cursors[indexBlock];
    }
    cursor?.classList.add('is-show');
}

const removeActiveClass = () => {
    cursor?.classList.remove('is-show');
};

function init(container: HTMLElement | Document = document) {
    blockWithCustomCursorArr = Array.from(container.querySelectorAll(SELECTOR));
    cursors = container.querySelectorAll('.js-custom-cursor');

    const setListeners = () => {
        blockWithCustomCursorArr.forEach((block) => {
            if (window.matchMedia('(any-hover: hover), (hover: hover) and (pointer: fine)').matches) {
                block.addEventListener('mousemove', setPositions);
                block.addEventListener('pointermove', setPositions);
                block.addEventListener('mouseenter', addActiveClass);
                block.addEventListener('mouseleave', removeActiveClass);
            } else {
                block.removeEventListener('mousemove', setPositions);
                block.removeEventListener('pointermove', setPositions);
                block.removeEventListener('mouseenter', addActiveClass);
                block.removeEventListener('mouseleave', removeActiveClass);
            }
        });
    };

    setListeners();

    window.addEventListener('resize', setListeners);
}

const _module = { init };

export default _module;
