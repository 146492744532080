import { Swiper } from 'swiper';
import { FreeMode } from 'swiper/modules';

function init(container: HTMLElement | Document = document) {
    const sliderArr = container.querySelectorAll<HTMLElement>('.js-free-scroll-slider');

    sliderArr.forEach((slider) => {
        const slides = slider.querySelectorAll('.swiper-slide');

        if (slides.length > 1) {
            new Swiper(slider, {
                modules: [FreeMode],
                slidesPerView: 'auto',
            });
        }
    });
}

const _module = { init };

export default _module;
