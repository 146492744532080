import { Swiper } from 'swiper';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import { createNavigation } from './slider-intro';

export const pauseAutoplay = (el: HTMLElement | any) => {
    const swiperEl = el.swiper;
    if (swiperEl && swiperEl.autoplay) {
        swiperEl.autoplay.stop();
    }
};

export const playAutoplay = (el: HTMLElement | any) => {
    const swiperEl = el.swiper;
    const sliderContainer = el.closest('.js-detail-slider-container');
    const progressbar = sliderContainer?.querySelector('.js-slider-progressbar');

    if (swiperEl && swiperEl.autoplay) {
        swiperEl.autoplay.start();

        progressbar?.classList.remove('is-active');
        setTimeout(() => {
            progressbar?.classList.add('is-active');
        }, 1);
    }
};

function init(container: HTMLElement | Document = document) {
    const sliderArr = container.querySelectorAll<HTMLElement>('.js-detail-slider');

    sliderArr.forEach((slider) => {
        if (slider.classList.contains('js-menu-slider') && window.matchMedia('(max-width: 1199px)').matches) return;

        const slides = slider.querySelectorAll('.swiper-slide');

        if (slides.length > 1) {
            const sliderContainer = slider.closest<HTMLElement>('.js-detail-slider-container');
            const textBlocks = sliderContainer?.querySelectorAll<HTMLElement>('.js-detail-slider-content');
            const navigation = sliderContainer?.querySelector<HTMLElement>('.js-slider-navigation');
            const countPagination = sliderContainer?.querySelector<HTMLElement>('.js-slider-pagination');
            if (navigation) createNavigation(navigation);
            const prevBtn = navigation?.querySelector<HTMLElement>('.js-slide-prev');
            const nextBtn = navigation?.querySelector<HTMLElement>('.js-slide-next');
            const autoplayDuration = 7000;

            let progressbar: HTMLElement | null | undefined;

            new Swiper(slider, {
                modules: [Pagination, Navigation, Autoplay],
                speed: 1000,
                pagination: {
                    el: countPagination,
                    type: 'fraction',
                    renderFraction: function (currentClass, totalClass) {
                        return (
                            '<span class="text-2xl intro-ctrl__current ' +
                            currentClass +
                            '"></span>' +
                            '<div class="intro-ctrl__progressbar js-slider-progressbar bvi-no-styles"></div>' +
                            '<span class="text-2xl intro-ctrl__current ' +
                            totalClass +
                            '"></span>'
                        );
                    },
                },
                navigation: {
                    nextEl: nextBtn,
                    prevEl: prevBtn,
                },
                loop: true,
                autoplay: {
                    delay: autoplayDuration,
                    disableOnInteraction: false,
                    waitForTransition: false,
                },
                on: {
                    init: () => {
                        progressbar = sliderContainer?.querySelector<HTMLElement>('.js-slider-progressbar');
                        if (sliderContainer) {
                            sliderContainer.style.setProperty('--autoplay-duration', `${autoplayDuration}ms`);
                        }

                        progressbar?.classList.remove('is-active');
                        setTimeout(() => {
                            progressbar?.classList.add('is-active');
                        }, 1);

                        if (textBlocks && textBlocks.length > 0) {
                            textBlocks.forEach((block) => block.classList.remove('is-active'));
                            textBlocks[0].classList.add('is-active');
                        }

                        if (slider.classList.contains('is-hiiden-slider')) {
                            pauseAutoplay(slider);
                        }
                    },
                    slideChange: (swiper) => {
                        progressbar?.classList.remove('is-active');
                        setTimeout(() => {
                            progressbar?.classList.add('is-active');
                        }, 1);

                        if (textBlocks && textBlocks.length > 0) {
                            textBlocks?.forEach((block) => block.classList.remove('is-active'));
                            setTimeout(() => {
                                textBlocks[swiper.realIndex].classList.add('is-active');
                            }, 500);
                        }
                    },
                },
            });
        }
    });
}

const _module = { init };

export default _module;
