import { SplitText } from 'gsap/SplitText';
const selector = '.js-reveal-mask';

function splittedText(el: HTMLElement) {
    el.classList.add('splittable');

    const type = el.classList.contains('js-words-split') ? 'words' : 'lines';
    new SplitText(el, {
        type,
        wordsClass: 'title-line-parent',
        linesClass: 'title-line-parent',
    });
    new SplitText(el.querySelectorAll('.title-line-parent'), {
        type,
    });
}

function init() {
    Array.from(document.querySelectorAll<HTMLElement>(selector)).forEach((el) => {
        splittedText(el);
    });
}

const _module = { init };

export default _module;
