import Swiper from 'swiper';
import { Navigation, Manipulation } from 'swiper/modules';
import ContentList from '@chipsadesign/content-list';

export function createNavigation(parent: HTMLElement) {
    parent.innerHTML = `
        <button class="deafult-slider-btn deafult-slider-btn--prev js-slide-prev" aria-label="предыдущий слайд">
            <svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 15.9999H29M29 15.9999L14.5 1.5M29 15.9999L14.5 30.4999" stroke="#0D0802" stroke-width="2.5"/>
            </svg>
        </button>
        <button class="deafult-slider-btn deafult-slider-btn--next js-slide-next" aria-label="следующий слайд">
            <svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 15.9999H29M29 15.9999L14.5 1.5M29 15.9999L14.5 30.4999" stroke="#0D0802" stroke-width="2.5"/>
            </svg>
        </button>
    `;
}

let teamSlider: Swiper;
const filtersSelect = document.querySelector<HTMLSelectElement>('.js-team-slider-filter');

function init(container: HTMLElement | Document = document) {
    const sliders = container.querySelectorAll<HTMLElement>('.js-team-slider');
    sliders.forEach((slider) => {
        const sliderContainer: HTMLElement | null = slider.closest('.js-team-slider-container');
        const navigation = sliderContainer?.querySelector<HTMLElement>('.js-slider-navigation');
        if (navigation) createNavigation(navigation);
        const prevCtrl = navigation?.querySelector<HTMLElement>('.js-slide-prev');
        const nextCtrl = navigation?.querySelector<HTMLElement>('.js-slide-next');

        teamSlider = new Swiper(slider, {
            modules: [Navigation, Manipulation],
            speed: 600,
            lazyPreloadPrevNext: 2,
            navigation: {
                prevEl: prevCtrl,
                nextEl: nextCtrl,
            },
            breakpoints: {
                320: {
                    spaceBetween: 15,
                    slidesPerView: 1.8,
                },
                577: {
                    spaceBetween: 40,
                    slidesPerView: 2.4,
                },
                960: {
                    spaceBetween: 40,
                    slidesPerView: 4,
                },
            },
        });
    });

    const catalogEl = document.querySelector<HTMLElement>('.js-catalog');
    if (catalogEl) {
        const select = document.querySelector('.js-select');
        const contentSlider = document.querySelector('.js-swiper-wrapper');

        const contentlist = new ContentList(catalogEl, {
            pushState: false,
            onFetchSuccess: (instance, response) => {
                if (contentSlider) {
                    setTimeout(() => {
                        contentSlider.innerHTML = response.data.peopleListHtml;
                        teamSlider.el?.classList.remove('is-out');
                    }, 500);
                }

                if (response.data?.nextUrl) {
                    window.history.pushState(null, '', response.data.nextUrl);
                }
            },
            onFetchError() {
                teamSlider.el?.classList.remove('is-out');
            },
            onFetchStart() {
                teamSlider.el?.classList.add('is-out');
            },
        });

        select?.addEventListener('change', () => {
            contentlist.fetchContent(`/about/?section_id=${filtersSelect?.value}`);
        });
    }
}

const _module = { init };

export default _module;
