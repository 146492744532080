const selector = '[data-reveal]';

const observer = new IntersectionObserver((entries, obs) => {
    entries.forEach(
        (entry) => {
            if (entry.isIntersecting) {
                obs.unobserve(entry.target);
                entry.target.classList.add('is-revealed');
            }
        },
        { rootMargin: '0px -40px 0px 0px' },
    );
});

function init(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLElement>(selector));
    elements.forEach((el) => {
        observer.observe(el);
    });
}

const _module = { init };

export default _module;
