const pictures = Array.from(document.querySelectorAll<HTMLDivElement>('.news-item__pic'));

const toggleHeaderPosition = () => {
    const bviPanel = document.querySelector<HTMLDivElement>('.bvi-panel');
    const header = document.querySelector<HTMLDivElement>('.js-header');
    const bviHideLink = document.querySelector<HTMLAnchorElement>('a[data-bvi="panel-hide"]');
    const bviCloseLink = document.querySelector<HTMLAnchorElement>('a[data-bvi="close"]');
    const page = document.querySelector<HTMLElement>('.main');
    const blockBtn = document.querySelector('.bvi-images-off');

    const offTop = () => {
        if (header && page) {
            header.style.top = '0px';
            page.style.paddingTop = '0px';
        }
    };

    const updatePicturesDisplay = () => {
        if (blockBtn?.classList.contains('active')) {
            pictures.forEach((el) => {
                el.style.display = 'none';
            });
        } else {
            pictures.forEach((el) => {
                el.style.display = 'block';
            });
        }
    };

    if (blockBtn) {
        const observer = new MutationObserver(() => {
            updatePicturesDisplay();
        });

        observer.observe(blockBtn, { attributes: true, attributeFilter: ['class'] });
        updatePicturesDisplay();
    }

    if (bviPanel && header && page && bviHideLink && bviCloseLink) {
        bviPanel.style.position = 'fixed';
        if (!bviPanel.classList.contains('bvi-panel-hide')) {
            header.style.top = `${bviPanel.offsetHeight}px`;
            page.style.paddingTop = `${bviPanel.offsetHeight}px`;
        } else offTop();

        bviCloseLink.addEventListener('click', offTop);
        bviHideLink.addEventListener('click', offTop);
    }
};

function init(container: HTMLElement | Document = document) {
    setTimeout(() => {
        const btn = container.querySelector('.bvi-link-fixed-top');
        btn?.addEventListener('click', toggleHeaderPosition);
    }, 3000);
    container.addEventListener('scroll', toggleHeaderPosition);
}

const _module = { init };

export default _module;
