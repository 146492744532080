import gsap from 'gsap';

function init() {
    const koef = matchMedia('(max-width: 1200px').matches ? 10 : 16;

    document.querySelectorAll<HTMLElement>('.js-parallax-el').forEach((el) => {
        const parallaxContainer = el.closest('.js-parallax-container');

        gsap.fromTo(
            el,
            {
                yPercent: -1 * koef,
            },
            {
                yPercent: koef,
                ease: 'none',
                scrollTrigger: {
                    trigger: parallaxContainer,
                    start: 'top bottom',
                    end: 'bottom top',
                    scrub: true,
                },
            },
        );
    });
}

const _module = { init };

export default _module;
