import { Swiper } from 'swiper';
import { FreeMode } from 'swiper/modules';

function init(container: HTMLElement | Document = document) {
    const slider = container.querySelector<HTMLElement>('.js-partners-slider');
    if (!slider) return;

    const slides = Array.from(slider.querySelectorAll<HTMLElement>('.swiper-slide'));
    let swiperEl: Swiper;

    let offsetSlides: number;

    function setOffset() {
        switch (true) {
            case window.matchMedia('(max-width: 767px)').matches:
                offsetSlides = 30;
                break;
            case window.matchMedia('(max-width: 1199px)').matches:
                offsetSlides = 16;
                break;
            case window.matchMedia('(max-width: 1600px)').matches:
                offsetSlides = 60;
                break;
            default:
                offsetSlides = 80;
        }
    }

    function initSlider() {
        if (!slider) return;
        const initialSlide = slides.length / 2;

        swiperEl = new Swiper(slider, {
            modules: [FreeMode],
            spaceBetween: offsetSlides,
            slidesPerView: 'auto',
            freeMode: true,
            initialSlide: initialSlide,
            centeredSlides: true,
            centeredSlidesBounds: true,
        });
    }

    function destroySlider() {
        if (swiperEl) {
            swiperEl.destroy();
        }
    }

    if (slides.length > 1) {
        function onResize() {
            setOffset();
            const slideWidth = slides[0].clientWidth;
            const isSlider = slideWidth * slides.length + offsetSlides * (slides.length - 1) > window.innerWidth;

            if (isSlider) {
                initSlider();
            } else {
                destroySlider();
            }
        }
        onResize();

        window.addEventListener('resize', onResize);
    }
}

const _module = { init };

export default _module;
