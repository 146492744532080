const changeTime = (e: Event) => {
    const target = e.currentTarget as HTMLElement;
    const datesContainer = target.closest('.js-dates-item');
    const ticketsLink = datesContainer?.querySelector<HTMLLinkElement>('.js-dates-item-link');
    const { href } = target.dataset;

    if (!ticketsLink || !href) return;

    ticketsLink.href = href;
    ticketsLink.classList.remove('is-disabled');
};

function init() {
    const timeTriggers = document.querySelectorAll<HTMLElement>('.js-dates-item-trigger');

    timeTriggers.forEach((trigger) => trigger.addEventListener('click', changeTime));
}

const _module = { init };

export default _module;
