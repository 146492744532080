import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';

let btnTimeout: NodeJS.Timeout;

function toggleTrigger(this: LitPopupElement, event: Event) {
    const open = event.type == 'open';
    if (!open) clearTimeout(btnTimeout);

    const popupName = this.dataset.litPopup;
    const trigger = document.querySelector(`[data-lit-popup-${open ? 'open' : 'close'}="${popupName}"]`);

    if (!trigger || !popupName) return;

    if (open) {
        trigger.classList.add('is-active');
        btnTimeout = setTimeout(() => {
            trigger.removeAttribute('data-lit-popup-open');
            trigger.setAttribute('data-lit-popup-close', popupName);
        }, 1);
    } else {
        trigger.classList.remove('is-active');
        trigger.removeAttribute('data-lit-popup-close');
        trigger.setAttribute('data-lit-popup-open', popupName);
    }
}

function init() {
    const popups = document.querySelectorAll<LitPopupElement>('.js-toggle-trigger');

    popups.forEach((popup) => {
        popup.addEventListener('open', toggleTrigger);
        popup.addEventListener('close-complete', toggleTrigger);
    });
}

const _module = { init };

export default _module;
