function onChange(this: HTMLInputElement) {
    if (!this.checked) return;

    const container = this.closest('.js-checkboxes-all-container');
    const checkboxes = container ? Array.from(container.querySelectorAll<HTMLInputElement>('.js-checkboxes-all')) : [];
    const checkedCheckboxes = checkboxes.filter((checkbox) => checkbox.checked);

    // если выбраны все чекбоксы, то выбор снимаем со всех
    if (checkedCheckboxes.length === checkboxes.length) {
        checkboxes.forEach((item) => (item.checked = false));
    }
}

function init() {
    const checkboxes = document.querySelectorAll<HTMLInputElement>('.js-checkboxes-all');
    checkboxes.forEach((item) => item.addEventListener('change', onChange));
}

const _module = { init };

export default _module;
