let positions = { top: 0, left: 0, x: 0, y: 0 };

let bindMouseMoveHandler: (e: MouseEvent) => void;
let bindMouseUpHandler: (e: MouseEvent) => void;
let documentWidth: number;

function changeDocumentWidth() {
    documentWidth = document.documentElement.clientWidth;
}

const mouseMoveHandler = function (this: HTMLElement, e: MouseEvent) {
    if (e.clientX <= 15 || e.clientX >= documentWidth - 15) {
        if (documentWidth > 768) {
            document.removeEventListener('mousemove', bindMouseMoveHandler);
            document.removeEventListener('mouseup', bindMouseUpHandler);
            return;
        }
    }

    const dx = e.clientX - positions.x;
    this.scrollLeft = positions.left - dx;
};

const mouseUpHandler = function (this: HTMLElement) {
    this.style.removeProperty('user-select');

    document.removeEventListener('mousemove', bindMouseMoveHandler);
    document.removeEventListener('mouseup', bindMouseUpHandler);
};

const mouseDownHandler = function (this: HTMLElement, e: MouseEvent) {
    if (e.button === 2) return;

    this.style.cssText = `
        -webkit-user-select: none;
        user-select: none;
    `;

    positions = {
        left: this.scrollLeft,
        top: this.scrollTop,
        x: e.clientX,
        y: e.clientY,
    };

    bindMouseMoveHandler = mouseMoveHandler.bind(this);
    bindMouseUpHandler = mouseUpHandler.bind(this);

    document.addEventListener('mousemove', bindMouseMoveHandler);
    document.addEventListener('mouseup', bindMouseUpHandler);
};

function init(container: HTMLElement | Document = document) {
    const scrollContainers = container.querySelectorAll<HTMLElement>('.js-drag-scroll');
    documentWidth = document.documentElement.clientWidth;

    scrollContainers.forEach((el) => {
        el.addEventListener('mousedown', mouseDownHandler);
    });
    window.addEventListener('resize', changeDocumentWidth);
}

const _module = { init };

export default _module;
