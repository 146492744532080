import { createPopper } from '@popperjs/core/';

function init() {
    const tooltips = document.querySelectorAll('.js-tooltip-container');

    tooltips.forEach((container) => {
        const trigger = container.querySelector('.js-tooltip-trigger');
        const tooltip = container.querySelector<HTMLElement>('.js-tooltip');
        let timer: NodeJS.Timeout;

        if (!trigger || !tooltip) return;

        tooltip.style.display = 'none';

        const popperInstance = createPopper(trigger, tooltip, {
            placement: 'top-start',
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [12, 18],
                    },
                },
            ],
        });

        function show() {
            clearTimeout(timer);
            if (tooltip) {
                tooltip.style.display = '';
                setTimeout(() => {
                    tooltip.classList.add('is-show');
                }, 1);
            }
            trigger?.classList.add('is-active');
            popperInstance.update();
        }

        function hide() {
            tooltip?.classList.remove('is-show');
            trigger?.classList.remove('is-active');

            timer = setTimeout(() => {
                if (tooltip) {
                    tooltip.style.display = 'none';
                }
            }, 300);
        }

        const showEvents = ['mouseenter', 'focus'];
        const hideEvents = ['mouseleave', 'blur'];

        showEvents.forEach((event) => {
            trigger.addEventListener(event, show);
        });

        hideEvents.forEach((event) => {
            trigger.addEventListener(event, hide);
        });
    });
}

const _module = { init };

export default _module;
