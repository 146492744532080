import lightGallery from 'lightgallery';

async function init() {
    const galleryArr = document.querySelectorAll<HTMLElement>('.js-gallery');
    galleryArr.forEach((gallery) => {
        lightGallery(gallery, {
            speed: 500,
            selector: '.js-gallery-link',
            counter: false,
            animateThumb: false,
            download: false,
            toggleThumb: true,
            zoomFromOrigin: false,
            allowMediaOverlap: true,
            getCaptionFromTitleOrAlt: false,
            licenseKey: '1111-1111-111-1111',
        });
    });
}

const _module = { init };

export default _module;
