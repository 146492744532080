import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import { createNavigation } from './slider-team';

function init(container: HTMLElement | Document = document) {
    const sliders = container.querySelectorAll<HTMLElement>('.js-certificates-slider');
    sliders.forEach((slider) => {
        const sliderContainer = slider.closest<HTMLElement>('.js-slider-container');
        const navigation = sliderContainer?.querySelector<HTMLElement>('.js-slider-navigation');
        if (navigation) createNavigation(navigation);
        const prevCtrl = navigation?.querySelector<HTMLElement>('.js-slide-prev');
        const nextCtrl = navigation?.querySelector<HTMLElement>('.js-slide-next');

        new Swiper(slider, {
            modules: [Navigation],
            speed: 600,
            lazyPreloadPrevNext: 2,
            navigation: {
                prevEl: prevCtrl,
                nextEl: nextCtrl,
            },
            breakpoints: {
                320: {
                    spaceBetween: 15,
                    slidesPerView: 1.8,
                },
                577: {
                    spaceBetween: 24,
                    slidesPerView: 2.9,
                },
                960: {
                    spaceBetween: 24,
                    slidesPerView: 5,
                },
                1601: {
                    spaceBetween: 40,
                    slidesPerView: 5,
                },
            },
        });
    });
}

const _module = { init };

export default _module;
