import SlimSelect from 'slim-select';

function init(container: HTMLElement | Document = document) {
    const selectList = container.querySelectorAll<HTMLElement>('.js-select');
    selectList.forEach((select) => {
        if (!select.classList.contains('select--initialization')) {
            const options = select.querySelectorAll('option');

            const newselect = new SlimSelect({
                select: select,
                settings: {
                    showSearch: false,
                    openPosition: 'auto',
                    hideSelected: true,
                },
            });

            if (options.length <= 1) {
                select.classList.add('is-not-open');
            }

            select.classList.add('select--initialization');
        }
    });
}

const _module = { init };

export default _module;
