import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

let historyContainer: HTMLElement | null;
let startScroll: number, previousTimeStamp: number;
let doneScroll = false;
let historyDirection: string;
let scrollValue: number;

function step(timeStamp: number) {
    if (!historyContainer) return;
    if (startScroll == undefined || startScroll == 0) {
        startScroll = timeStamp;
    }
    const elapsed = timeStamp - startScroll;

    if (previousTimeStamp != timeStamp) {
        const count = Math.min(0.16 * elapsed, scrollValue);

        if (historyDirection == 'left') {
            historyContainer.scrollLeft -= scrollValue / 3;
        } else {
            historyContainer.scrollLeft += scrollValue / 3;
        }
        if (count === scrollValue) doneScroll = true;
    }

    if (elapsed < 1000) {
        previousTimeStamp = timeStamp;
        if (!doneScroll) {
            window.requestAnimationFrame(step);
        }
    }
}

function setInnerPosition(e: WheelEvent) {
    historyDirection = e.deltaY < 0 ? 'left' : 'right';
    scrollValue = e.deltaY > -100 && e.deltaY < 100 ? 20 : 40;

    if (historyContainer) {
        doneScroll = false;
        startScroll = 0;
        window.requestAnimationFrame(step);
    }
}

function init(container: Element | Document = document) {
    historyContainer = container.querySelector<HTMLElement>('.js-history-inner');
    historyContainer?.addEventListener('wheel', setInnerPosition);
    const historyItems = Array.from(container.querySelectorAll<HTMLElement>('.js-history-item'));

    historyItems.forEach((item) => {
        const imageFull = item.querySelector('.js-history-image-full');
        const imageMedium = item.querySelector('.js-history-image-medium');

        ScrollTrigger.matchMedia({
            '(any-hover: hover), (hover: hover) and (pointer: fine)': function () {
                if (imageFull) {
                    gsap.fromTo(
                        imageFull,
                        {
                            xPercent: -20,
                        },
                        {
                            xPercent: 20,
                            ease: 'none',
                            scrollTrigger: {
                                trigger: item,
                                start: 'top bottom',
                                end: 'bottom top',
                                horizontal: true,
                                scroller: '.js-history-inner',
                                scrub: 0.1,
                            },
                        },
                    );
                }
                if (imageMedium) {
                    gsap.fromTo(
                        imageMedium,
                        {
                            scale: 1.2,
                        },
                        {
                            scale: 1,
                            ease: 'none',
                            scrollTrigger: {
                                trigger: item,
                                start: 'top bottom',
                                end: 'top top-=50%',
                                horizontal: true,
                                scroller: '.js-history-inner',
                                scrub: 0.1,
                            },
                        },
                    );
                }
            },
            '(pointer: coarse)': function () {
                if (imageFull) {
                    gsap.fromTo(
                        imageFull,
                        {
                            xPercent: -10,
                        },
                        {
                            xPercent: 10,
                            ease: 'none',
                            scrollTrigger: {
                                trigger: item,
                                start: 'top bottom',
                                end: 'bottom top',
                                horizontal: true,
                                scroller: '.js-history-wrapper',
                                scrub: 0.1,
                            },
                        },
                    );
                }
                if (imageMedium) {
                    gsap.fromTo(
                        imageMedium,
                        {
                            scale: 1.1,
                        },
                        {
                            scale: 1,
                            ease: 'none',
                            scrollTrigger: {
                                trigger: item,
                                start: 'top bottom',
                                end: 'top top-=50%',
                                horizontal: true,
                                scroller: '.js-history-wrapper',
                                scrub: 0.1,
                            },
                        },
                    );
                }
            },
        });
    });
}

const _module = { init };

export default _module;
