import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';
import { pauseAutoplay, playAutoplay } from './slider-detail';

const header = document.querySelector('.js-header');
const menuBtn = document.querySelector<HTMLElement>('.js-menu-btn');
const slider = document.querySelector<HTMLElement>('.js-menu-slider');
let btnTimeout: NodeJS.Timeout;

function onOpen() {
    header?.classList.add('header--menu-opened');

    if (slider && window.matchMedia('(min-width: 1200px)').matches) {
        playAutoplay(slider);
    }

    if (menuBtn) {
        menuBtn.classList.add('is-active');
        btnTimeout = setTimeout(() => {
            menuBtn.removeAttribute('data-lit-popup-open');
            menuBtn.setAttribute('data-lit-popup-close', 'menu');
        }, 1);
    }
}

function onCloseComplete() {
    clearTimeout(btnTimeout);
    header?.classList.remove('header--menu-opened');

    if (slider && window.matchMedia('(min-width: 1200px)').matches) {
        pauseAutoplay(slider);
    }

    if (menuBtn) {
        menuBtn.classList.remove('is-active');
        menuBtn.removeAttribute('data-lit-popup-close');
        menuBtn.setAttribute('data-lit-popup-open', 'menu');
    }
}

function init() {
    const menu = document.querySelector<LitPopupElement>('.js-menu');

    if (menu) {
        menu.addEventListener('open', onOpen);
        menu.addEventListener('close-complete', onCloseComplete);
    }
}

const _module = { init };

export default _module;
