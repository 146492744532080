import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';
import { pauseAutoplay, playAutoplay } from './slider-detail';

// any для swiper
let popupSlider: any;
let pageSlider: any;

function onOpen() {
    if (pageSlider) {
        popupSlider.swiper.slideToLoop(pageSlider.swiper.realIndex, 0, false);
        pauseAutoplay(pageSlider);
    }

    playAutoplay(popupSlider);
}

function onCloseComplete() {
    pauseAutoplay(popupSlider);

    if (pageSlider) {
        pageSlider.swiper.slideToLoop(popupSlider.swiper.realIndex, 0, false);
        playAutoplay(pageSlider);
    }
}

function init() {
    const popup = document.querySelector<LitPopupElement>('.js-gallery-popup');
    popupSlider = popup?.querySelector<HTMLElement>('.js-detail-slider.is-popup-slider');
    pageSlider = document.querySelector<HTMLElement>('.js-detail-slider.is-page-slider');

    if (popup && popupSlider) {
        popup.addEventListener('open', onOpen);
        popup.addEventListener('close-complete', onCloseComplete);
    }
}

const _module = { init };

export default _module;
